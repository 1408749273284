@import "../../../src/scss/themes/basic/abstracts/custom_variables";
@import "../../../src/scss/themes/basic/abstracts/custom_mixins";
@import "_default/block_content";

.block_content {
    &.white .content {
        background-color: $color-white;
        padding: 43px 36px;
        border-radius: 30px;
    }

    &.firstelem{
        margin-top:120px;
    }
}
