//Theme variables
$theme-name: 'basic';

//Defaults
$default-size: 16px;

//Hard colors
$color-black: #000000;
$color-white: #FFFFFF;

//Colors
$color-primary: #8DC63F;
$color-secondary: #313A45;
$color-secondary-two: #637184;
$color-secondary-three: #A1AEC1;
$color-secondary-four: #E1E7F0;
$color-secondary-five: #EFF3FA;
$color-secondary-six: #F7F9FD;
$color-secondary-seven: #CFFF8E;
$color-tertiary: #64C9FF;
$color-tertiary-two: #CF81FF;
$color-tertiary-three: #FF4065;
$color-tertiary-four: #FE6540;
$color-cta: #009EEF;
$hover-color-cta: #1C6C94;

$color-error: $color-tertiary-three;
$color-form-borders: #CCCCCC;
$color-menu-bg: #F0F0F0;
$color-yellow: #F9B000;
$color-orange: #D87D08;
$color-green: #33671A;
$color-blue: #1F262F;
$color-grey: $color-secondary-two;
$color-lightgrey: $color-secondary-five;
$color-yellow-arrow: $color-tertiary-four;

//Text colors
$color-text: $color-secondary;
$color-heading: $color-blue;
$color-text-light: #5E5E5E;

//Font variables
$mainfont: 'Titillium Web';

//Font weights
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

//Headings
$heading-h1-max: ceil(($default-size * 4.375)); // ~70px
$heading-h1-min: ceil(($default-size * 1.75)); // ~28px
$heading-h2-max: ceil(($default-size * 1.75)); // ~28px
$heading-h2-min: ceil(($default-size * 1.375)); // ~22px
$heading-h3-max: ceil(($default-size * 1.375)); // ~22px
$heading-h3-min: ceil(($default-size * 1.375)); // ~22px
$heading-h4-max: ceil(($default-size * 1.25)); // ~20px
$heading-h4-min: ceil(($default-size * 1.25)); // ~20px
$heading-h5: ceil(($default-size * 1.25)); // ~20px
$heading-h6: ceil(($default-size * 1.25)); // ~20px

//Text
$font-xl: ceil(($default-size * 1.25)); // ~20px
$font-l: ceil(($default-size * 1.125)); // ~18px
$font-m: ceil(($default-size * 1)); // ~16px
$font-s: ceil(($default-size * 0.875)); // ~14px
$font-menu: ceil(($default-size * 3.75)); // ~60px
$font-form: ceil(($default-size * 0.75)); // ~12px

//Other text styles
$font-quote-max: ceil(($default-size * 2)); // ~32px
$font-quote-min: ceil(($default-size * 1.625)); // ~26px

//Font settings
$text-settings: (
        'font-heading-h1': (
                font-family: $mainfont,
                font-size: clamp($heading-h1-min, 4vw, $heading-h1-max),
                font-weight: $black,
                line-height: 96px,
        ),
        'font-heading-h2': (
                font-family: $mainfont,
                font-size: clamp($heading-h2-min, 4vw, $heading-h2-max),
                font-weight: $bold,
                line-height: 38px,
        ),
        'font-heading-h3': (
                font-family: $mainfont,
                font-size: clamp($heading-h3-min, 4vw, $heading-h3-max),
                font-weight: $bold,
                line-height: 30px,
        ),
        'font-heading-h4': (
                font-family: $mainfont,
                font-size: clamp($heading-h4-min, 4vw, $heading-h4-max),
                font-weight: $bold,
                line-height: 38px,
        ),
        'font-heading-h5': (
                font-family: $mainfont,
                font-size: $heading-h5,
                font-weight: $bold,
                line-height: 38px,
        ),
        'font-heading-h6': (
                font-family: $mainfont,
                font-size: $heading-h6,
                font-weight: $bold,
                line-height: 38px,
        ),
        'font-text-xl': (
                font-family: $mainfont,
                font-size: $font-xl,
                line-height: 30px,
        ),
        'font-text-l': (
                font-family: $mainfont,
                font-size: $font-l,
                line-height: 30px,
        ),
        'font-text-m': (
                font-family: $mainfont,
                font-size: $font-m,
                line-height: 30px,
        ),
        'font-text-s': (
                font-family: $mainfont,
                font-size: $font-s,
                line-height: 30px,
        ),
        'font-quote': (
                font-family: $mainfont,
                font-size: clamp($font-quote-min, 4vw, $font-quote-max),
                line-height: 125%,
        )
);

;@import "sass-embedded-legacy-load-done:55";