@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_content {
    margin: 50px 0;

    .heading-h2 {
        margin-bottom:35px;
    }
}

;@import "sass-embedded-legacy-load-done:215";